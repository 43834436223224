import {HelperMedium} from "styles/Typography";
import styled from "styled-components";
import MeetingCard from "./Card";

const MeetingList = () => {

    const meetings = {
        "Today (Jan 23)": [
            {
                title: "Project Kickoff",
                dateStart: "Mon, Sep 14",
                timeStart: "09:00",
                period: "1 hour"
            },
            {
                title: "Design Review",
                dateStart: "Mon, Sep 14",
                timeStart: "11:00",
                period: "30 min"
            },
            {
                title: "Client Meeting",
                dateStart: "Mon, Sep 14",
                timeStart: "14:00",
                period: "2 hours"
            },
            {
                title: "Team Standup",
                dateStart: "Mon, Sep 14",
                timeStart: "16:30",
                period: "15 min"
            }
        ],
        "Tomorrow (Jan 24)": [
            {
                title: "Code Review",
                dateStart: "Tue, Sep 15",
                timeStart: "10:00",
                period: "1 hour"
            },
            {
                title: "Marketing Strategy Meeting",
                dateStart: "Tue, Sep 15",
                timeStart: "13:00",
                period: "45 min"
            },
            {
                title: "Development Sync",
                dateStart: "Tue, Sep 15",
                timeStart: "15:00",
                period: "30 min"
            },
            {
                title: "Weekly Wrap-up",
                dateStart: "Tue, Sep 15",
                timeStart: "17:00",
                period: "1 hour"
            }
        ],
        "Wednesday (Jan 25)": [
            {
                title: "Quarterly Planning",
                dateStart: "Wed, Sep 16",
                timeStart: "09:00",
                period: "3 hours"
            },
            {
                title: "One-on-One",
                dateStart: "Wed, Sep 16",
                timeStart: "13:00",
                period: "1 hour"
            },
            {
                title: "Engineering All-hands",
                dateStart: "Wed, Sep 16",
                timeStart: "15:00",
                period: "1.5 hours"
            }
        ],
    }

    return (
        <>
            {Object.entries(meetings).map(([date, meetingsArray]) => (
                <MeetingBlock key={date}>
                    <MeetingDate>
                        <HelperMedium>{date}</HelperMedium>
                    </MeetingDate>
                    <ContainerAgendas>
                        {meetingsArray.map((meeting, index) => (
                            <MeetingCard
                                key={index}
                                title={meeting.title}
                                dateStart={meeting.dateStart}
                                timeStart={meeting.timeStart}
                                period={meeting.period}
                            />
                        ))}
                    </ContainerAgendas>
                </MeetingBlock>
            ))}
        </>
    );
};

const MeetingBlock = styled.div`
    margin-bottom: 24px;
`;

const ContainerAgendas = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const MeetingDate = styled.div`
    color: ${({theme}) => theme.colors.Neutral["50"]};
    margin-bottom: 12px;
`;

export default MeetingList;
