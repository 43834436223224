import React from 'react';
import styled from "styled-components";
import SwitchLabel from "./SwitchLabel";

const Toggle = ({label, value, onChange}) => {
    return (
        <ToggleContainer>
              <SwitchLabel text={label}/>
        </ToggleContainer>
    );
};
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default Toggle;