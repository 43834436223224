import React from "react";
import styled from "styled-components";
import {Body1} from "styles/Typography";
import {useLocation, useNavigate} from "react-router-dom";

const Tab = ({label, linkTo}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const normalizePath = (path) => path.replace(/\/+$/, '');
    const isActive = normalizePath(location.pathname) === normalizePath(linkTo);

    return (
        <>
            <TabContainer
                selected={isActive}
                onClick={() => {
                    navigate(linkTo)
                }}
            >
                <TextContainer>
                    <Body1>{label}</Body1>
                </TextContainer>
                {isActive && <Border/>}
            </TabContainer>
        </>
    );
}

export default Tab;

const TabContainer = styled.div`
    height: 42px;
    margin: 0 8px;
    color: ${({theme, selected}) => (selected ? theme.colors.Neutral["80"] : theme.colors.Neutral["70"])};
    cursor: pointer;
`;

const TextContainer = styled.div`
    padding: 4px 8px;
    margin-bottom: 6px;
    &:hover {
        background-color: ${({theme}) => theme.colors.Neutral["25"]};
        border-radius: 6px;
    }
`;

const Border = styled.div`
    height: 4px;
    background-color: ${({theme}) => theme.colors.Neutral["70"]};
    border-radius: 2px 2px 0 0;
    width: 40px;
    margin: 0 auto;
`