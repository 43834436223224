import React from 'react';
import styled from 'styled-components';
import SupportingInfo from "pages/login/supportInfo";
import LogInComponent from "pages/login/logInComponent";


const LoginPage = () => {
    return (
        <MainContainer>
            <LeftBox>
                <SupportingInfo/>
            </LeftBox>
            <RightBox>
                <LogInComponent/>
            </RightBox>
        </MainContainer>
    );
};

export default LoginPage;

const MainContainer = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
`;

const LeftBox = styled.div`
    height: auto;
    width: 50%;
    position: relative;
    background-color: #140027;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const RightBox = styled.div`
    width: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
`;