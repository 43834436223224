import React, {useEffect, useState} from "react";
import Item from "./Item";
import ItemCreate from "./ItemCreate";
import styled from "styled-components";
import {Body2} from "styles/Typography";
import {getRequest} from "../../../utils/api";


const ItemList = () => {
    const [itemsData, setItemsData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getRequest("/agenda/");
                if (result) {
                    setItemsData(result);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [])
    return (
        <Container>
            <Name>
                <Body2>[Manage meeting agenda templates]</Body2>
            </Name>
            <ContainerAgendas>
                {itemsData.map((item, index) => (
                    <Item
                        key={index}
                        name={item.title}
                        description={item.description}
                    />
                ))}
                <ItemCreate/>
            </ContainerAgendas>
        </Container>
    );
};

const Container = styled.div`
    width: max-content;
    margin: 0 auto;
`
const Name = styled.div`
    color: ${({theme}) => theme.colors.Neutral["60"]};
    margin-bottom: 24px;
`

const ContainerAgendas = styled.div`
    display: grid;
    gap: 20px;
    max-width: 880px;
    grid-template-columns: 1fr 1fr 1fr;
`;


export default ItemList