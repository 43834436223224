import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {SearchableSelect, Select} from "components/forms";

import {formatDate} from "utils/date";

import InfosContainer from "./InfosContainer";
import PlotsContainer from "./PlotsContainer";
import {getRequest} from "utils/api";

function transformData(inputArray) {
    return inputArray.map(item => ({
        value: item.id,
        label: item.email
    }));
}

const OverviewPage = () => {
    const [selMember, setSelMember] = useState("all");

    const [periodValue, setPeriodValue] = useState("current_month");

    const [typeView, setTypeView] = useState("week")

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [member, setMember] = useState([])

    const getCurrentMonthAbbreviation = () => {
        const now = new Date();
        return formatDate(now, 'MMM');
    };

    const periodOptions = [
        [
            {value: "next_30_days", label: "Next 30 days"},
            {value: "last_30_days", label: "Last 30 days"},
            {value: "current_month", label: `Current month (${getCurrentMonthAbbreviation()})`},
        ], [
            {value: "custom", label: "Custom", icon: "calendar-event-line"},
        ]
    ]
    const viewOptions = [
        {value: "week", label: "Weekly view"},
        {value: "day", label: "Daily view"},
    ]
    const memberOptions = [
        [{value: "all", label: "Whole team"}],
        member
    ]
    const handleChange = (value) => {
        setSelMember(value);
    }

    const handleTeamChange = (value) => {
        setTypeView(value)
    }

    const handleChangeSelectPeriod = (value) => {
        setPeriodValue(value);
    }

    useEffect(() => {
        let start, end;
        switch (periodValue) {
            case 'current_month':
                start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                end = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
                break;
            case 'next_30_days':
                start = new Date();
                end = new Date();
                end.setDate(start.getDate() + 30);
                break;
            case 'last_30_days':
                end = new Date();
                start = new Date();
                start.setDate(end.getDate() - 30);
                break;
            default:
                start = '';
                end = '';
                break;
        }
        setStartDate(formatDate(start));
        setEndDate(formatDate(end));
    }, [periodValue])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getRequest("/team/");
                if (result) {
                    setMember(transformData(result));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [])

    return (
        <MainContainer>
            <MenuContainer>
                <SearchableSelect selectedValue={selMember} onValueChange={handleChange} options={memberOptions}
                                  placeholderInput="Search by name or email"/>
                <div></div>
                <Select selectedValue={periodValue} onValueChange={handleChangeSelectPeriod} options={periodOptions}/>
                <Select selectedValue={typeView} onValueChange={handleTeamChange} options={viewOptions}/>
            </MenuContainer>
            <InfosContainer selMember={selMember} startDate={startDate} endDate={endDate} typeView={typeView}/>
            <PlotsContainer selMember={selMember} startDate={startDate} endDate={endDate} typeView={typeView}/>
        </MainContainer>
    )
};

export default OverviewPage;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`

const MenuContainer = styled.div`
    display: grid;
    grid-template-columns: 278px 1fr 200px 132px;
    grid-column-gap: 16px;
`;
