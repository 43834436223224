import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'components/forms';
import { Helper } from 'styles/Typography';

const InfoTooltip = ({ text, position = 'none' }) => {
    const [isVisible, setIsVisible] = useState(false);
    const showTooltip = () => setIsVisible(true);
    const hideTooltip = () => setIsVisible(false);

    return (
        <TooltipContainer>
            <TooltipIcon
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
            >
                <Icon type={"information-line"} />
            </TooltipIcon>
            {isVisible && (
                <TooltipBox position={position}>
                    <Helper>{text}</Helper>
                    {position !== 'none' && <TooltipArrow position={position} />}
                </TooltipBox>
            )}
        </TooltipContainer>
    );
};

const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const TooltipIcon = styled.span`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.Neutral["60"]};
    display: flex;
    align-items: center;

    &:hover {
        color: ${({ theme }) => theme.colors.Neutral["80"]};
    }
`;

const positionStyles = {
    none: css`
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 8px;
    `,
    top: css`
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 8px;
    `,
    top_left: css`
        bottom: 100%;
        left: 0;
        margin-bottom: 8px;
    `,
    top_right: css`
        bottom: 100%;
        right: 0;
        margin-bottom: 8px;
    `,
    bottom: css`
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 8px;
    `,
    bottom_right: css`
        top: 100%;
        right: 0;
        margin-top: 8px;
    `,
    bottom_left: css`
        top: 100%;
        left: 0;
        margin-top: 8px;
    `,
    right: css`
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        margin-left: 8px;
    `,
    left: css`
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        margin-right: 8px;
    `,
};

const TooltipBox = styled.div`
    position: absolute;
    padding: 6px 10px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.Neutral["80"]};
    color: ${({ theme }) => theme.colors.Neutral["15"]};
    white-space: nowrap;
    z-index: 10;
    ${({ position }) => positionStyles[position]}
`;

const TooltipArrow = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;

    ${({ position, theme }) => position === 'top' && css`
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 5px 5px 0 5px;
        border-color: ${theme.colors.Neutral["80"]} transparent transparent transparent;
    `}

    ${({ position, theme }) => position === 'bottom' && css`
        top: -5px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent ${theme.colors.Neutral["80"]} transparent;
    `}

    ${({ position, theme }) => position === 'right' && css`
        left: -5px;
        top: 50%;
        transform: translateY(-50%);
        border-width: 5px 5px 5px 0;
        border-color: transparent ${theme.colors.Neutral["80"]} transparent transparent;
    `}

    ${({ position, theme }) => position === 'left' && css`
        right: -5px;
        top: 50%;
        transform: translateY(-50%);
        border-width: 5px 0 5px 5px;
        border-color: transparent transparent transparent ${theme.colors.Neutral["80"]};
    `}

    ${({ position, theme }) => (position === 'top_left' || position === 'top_right') && css`
        bottom: -5px;
        ${position === 'top_left' ? 'left: 10%;' : 'right: 10%;'}
        border-width: 5px 5px 0 5px;
        border-color: ${theme.colors.Neutral["80"]} transparent transparent transparent;
    `}

    ${({ position, theme }) => (position === 'bottom_left' || position === 'bottom_right') && css`
        top: -5px;
        ${position === 'bottom_left' ? 'left: 10%;' : 'right: 10%;'}
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent ${theme.colors.Neutral["80"]} transparent;
    `}
`;

export default InfoTooltip;
