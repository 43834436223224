import React from 'react';
import {authProvider} from "utils/auth";
import styled from 'styled-components';

import Logo from 'assets/images/logo_image.svg';

import {ButtonLogIn} from "components/forms";


const LogInComponent = () => {

    const handleCLickPrivacyPolicy = (e) => {
        e.preventDefault();
        window.open('https://spryplan.com/privacy_policy', '_blank');
    };

    const handleClickTermsOfUse = (e) => {
        e.preventDefault();
        window.open('https://spryplan.com/terms_of_use', '_blank');
    };

    const handleGoogleSignIn = async () => {
        try {
            await authProvider.signInGoogle();
            await authProvider.signInExtension();
            window.open('/team_view/overview/');
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <LoginContainer>
            <LogoImage src={Logo}/>
            <TextHeading>Sign up to get all the features</TextHeading>
            <ChromeLogin>
                <ButtonLogIn
                    onClick={handleGoogleSignIn}
                />
            </ChromeLogin>
            <UserInfo>
                <Terms>
                    By using Spry Plan you agree to the{' '}
                    <CustomLink onClick={handleClickTermsOfUse}>
                        Terms of Use
                    </CustomLink>{' '}
                    and{' '}
                    <CustomLink onClick={handleCLickPrivacyPolicy}>
                        Privacy Policy
                    </CustomLink>
                </Terms>
            </UserInfo>
        </LoginContainer>
    );
};

export default LogInComponent;

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

const LogoImage = styled.img`
    width: 132px;
    height: 24px;
    margin-bottom: 224px;
    margin-top: 50px;
`;

const TextHeading = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    font-size: 3vh;
    display: flex;
    color: #000000;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const ChromeLogin = styled.div`
    margin-top: 64px;
    margin-bottom: 10px;
`;

const Terms = styled.div`
    text-align: center;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1.5vh;
    margin-top: 342px;
    margin-bottom: 24px;
`;

const CustomLink = styled.a`
    text-decoration: none;
    color: #146ef6;
    cursor: pointer;
    font-family: 'Inter';
`;