 /*global chrome*/
import {browserLocalPersistence, GoogleAuthProvider, setPersistence, signInWithPopup, signOut} from "firebase/auth";
import {auth} from "./firebaseConfig";

export const authProvider = {
    accessToken: null,
    async signInGoogle() {
        const googleProvider = new GoogleAuthProvider();
        googleProvider.addScope('https://www.googleapis.com/auth/calendar');
        googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
        googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        googleProvider.addScope('openid');

        googleProvider.setCustomParameters({
            prompt: 'select_account consent',
        });

        await setPersistence(auth, browserLocalPersistence);
        const result = await signInWithPopup(auth, googleProvider);
        authProvider.accessToken = result.accessToken;
    },
    async signInExtension() {
        const user = auth.currentUser;
        const email = user.email;
        const accessToken = auth.accessToken;
        const refreshToken = user.refreshToken;
        const idToken = await user.getIdToken();
        const displayName = user.displayName;
        const photoURL = user.photoURL;
        const userInfo = {
            accessToken,
            refreshToken,
            idToken,
            email,
            displayName,
            photoURL,
        }
        chrome.runtime.sendMessage(
            "neeejkmamhpfacjdnbeoblbkplgdgihf",
            {user: userInfo, action: 'login'},
            function (response) {
                if (!response.success) console.log(response);
            }
        );

    },
    async signout() {
        await signOut(auth);
        authProvider.accessToken = null;
    },
    init(user) {
        if (user) {
            authProvider.accessToken = user.accessToken;
        } else {
            authProvider.accessToken = null;
        }
    }
};