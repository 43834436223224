import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Body2} from "styles/Typography";
import {DropDownBlock, DropDownContainer, DropDownItem, Input, Label} from "components/forms";

const SearchableSelect = ({
                              label,
                              selectedValue,
                              onValueChange,
                              options,
                              placeholder = "",
                              size = "small",
                          }) => {
    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(options);
    const dropdownRef = useRef(null);

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleOptionSelect = (value) => {
        onValueChange(value);
        setIsDropDownVisible(false);
    };

    const toggleDropDownVisibility = () => {
        setIsDropDownVisible(!isDropDownVisible);
        setSearchTerm("");
    };

    useEffect(() => {
        const updateFilteredOptions = () => {
            if (searchTerm.trim() !== "") {
                const filtered = options.map(group =>
                    group.filter(option =>
                        option.label.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                );
                setFilteredOptions(filtered);
            } else {
                setFilteredOptions(options);
            }
        };

        updateFilteredOptions();
    }, [searchTerm, options]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropDownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectedOption = options.flat().find(option => option.value === selectedValue);

    return (
        <Container ref={dropdownRef}>
            <Label text={label} size={size}/>
            <Input
                size={size}
                value={!isDropDownVisible && selectedOption ? selectedOption.label : searchTerm}
                placeholder={placeholder}
                onChange={handleInputChange}
                onClick={toggleDropDownVisibility}
                icon={isDropDownVisible ? "arrow-up-s-line" : "arrow-down-s-line"}
                iconPosition="right"
            />
            {isDropDownVisible && (
                <DropDownContainer>
                    {filteredOptions.flat().length > 0 ? (
                        filteredOptions.map((group, index) => (
                            <React.Fragment key={index}>
                                <DropDownBlock
                                    value={selectedValue}
                                    options={group}
                                    onSelect={handleOptionSelect}
                                    highlightSelected={true}
                                />
                                {index < filteredOptions.length - 1 && <Divider/>}
                            </React.Fragment>
                        ))
                    ) : (
                        <DropDownItem>
                            <NoResultsMessage>
                                No results for <HighlightedText>"{searchTerm}"</HighlightedText>
                            </NoResultsMessage>
                        </DropDownItem>
                    )}
                </DropDownContainer>
            )}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
`;



const NoResultsMessage = styled(Body2)`
    color: ${(props) => props.theme.colors.Neutral["50"]};
`;

const HighlightedText = styled(Body2)`
    color: ${(props) => props.theme.colors.Neutral["70"]};
`;

const Divider = styled.div`
    height: 1px;
    background-color: ${({theme}) => theme.colors.Neutral["30"]};
    margin: 0 8px 4px 8px;
`;

export default SearchableSelect;
