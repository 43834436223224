import styled from "styled-components";

const Container = styled.div`
    width: ${props => props.width ? props.width : "100%"};
    padding: 4px;
    position: absolute;
    top: 110%;
    box-sizing: border-box;
    background: ${({theme}) => theme.colors.Neutral["0"]};
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
`;

const ScrollableContent = styled.div`
    padding: 4px;
    max-height: 212px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${({theme}) => theme.colors.Neutral["50"]};
        border-radius: 4px;
    }
`;

export default function DropdownContainer({ width, children }) {
    return (
        <Container width={width}>
            <ScrollableContent>
                {children}
            </ScrollableContent>
        </Container>
    );
}
