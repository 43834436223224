import React from "react";
import styled from "styled-components";
import {Body2} from "styles/Typography";

const SwitchLabel = ({text, size = "small",}) => {
    if (!text) return null;
    return (
        <LabelText size={size}>{text}</LabelText>
    );
};

const LabelText = styled(Body2)`
    color: ${({theme}) => theme.colors.Neutral["70"]};
    margin-left: 8px;
`;

export default SwitchLabel;
