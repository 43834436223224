import React from "react";
import styled from "styled-components";
import {Body2Medium} from "styles/Typography";

const Label = ({text, size = "small",}) => {
    if (!text) return null;
    return (
        <LabelText size={size}>{text}</LabelText>
    );
};

const LabelText = styled(Body2Medium)`
    color: ${({theme}) => theme.colors.Neutral["60"]};
    margin-bottom: 2px;
`;

export default Label;
