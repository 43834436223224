import React from 'react';
import styled from "styled-components";
import {Icon} from "../index";


const DropDownItem = ({icon = "", children, onClick, isSelected, disabled, size = "small"}) => (
    <StyledDropdownItem onClick={onClick} isSelected={isSelected} disabled={disabled} size={size}>
        {children}
        {isSelected && <Icon size={18} type="check-line"/>}
    </StyledDropdownItem>
);

export default DropDownItem;

const StyledDropdownItem = styled.div`
    padding: 6px 8px;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    height: ${props => props.size === "small" ? "32px" : "40px"};
    background: ${(props) => (props.isSelected ? props.theme.colors.Neutral["20"] : props.theme.colors.Neutral["0"])};
    color: ${(props) => (props.isSelected ? props.theme.colors.Neutral["80"] :
            props.theme.colors.Neutral["70"])};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 4px;

    &:hover {
        background: ${(props) => (props.isSelected ?  props.theme.colors.Neutral["0"] :
                props.theme.colors.Neutral["20"])};
    }
`;
