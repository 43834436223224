import { Subheader1, Helper } from "styles/Typography";
import styled from "styled-components";
import { useState } from "react";
import { Button } from "components/forms";

const MeetingCard = ({ title, dateStart, timeStart, period }) => {
    const [isHover, setIsHover] = useState(false);

    return (
        <CardContainer
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <CardTitle>
                <Subheader1>{title}</Subheader1>
                {isHover && (
                    <ButtonOverlay>
                        <Button text="Add agenda" size="small" />
                    </ButtonOverlay>
                )}
            </CardTitle>
            <CardDetails>
                <Helper>{dateStart} &bull; {timeStart} &bull; ({period}) &bull; 2 guests</Helper>
            </CardDetails>
        </CardContainer>
    );
};

const CardContainer = styled.div`
    position: relative;
    height: 82px;
    width: 294px;
    padding: 16px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.Neutral["0"]};
    box-sizing: border-box;

    &:hover {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.Neutral["50"]};
    }
`;

const CardTitle = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.colors.Neutral["80"]};
    margin-bottom: 12px;
`;

const ButtonOverlay = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
`;

const CardDetails = styled.div`
    color: ${({ theme }) => theme.colors.Neutral["60"]};
`;

export default MeetingCard;
