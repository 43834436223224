import React from 'react';
import styled from 'styled-components';
import LoginButton from "assets/images/button/button.svg";
import HoveredGoogle from "assets/images/button/hovered_google.svg";

const ButtonLogIn = ({onClick}) => {
    return (
        <ChromeLogin src={LoginButton} onClick={onClick}/>
    );
};

const ChromeLogin = styled.img`
    cursor: pointer;
    &:hover {
        content: url(${HoveredGoogle});
    }

`;

export default ButtonLogIn;