import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Icon, DropDownContainer, DropDownBlock, DropDownLabel} from 'components/forms';


const Select = ({selectedValue, onValueChange, options, size = 'small'}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdownVisibility = () => setIsOpen(!isOpen);

    const selectOption = (value) => {
        onValueChange(value);
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const hasNestedOptions = Array.isArray(options[0]);
    const allOptions = options.flat();
    const currentOption = allOptions.find(option => option.value === selectedValue);

    return (
        <DropdownWrapper ref={dropdownRef}>
            <DropdownTrigger onClick={toggleDropdownVisibility} size={size}>
                <DropDownLabel option={currentOption}/>
                <Icon size={18} type={isOpen ? 'arrow-up-s-line' : 'arrow-down-s-line'}/>
            </DropdownTrigger>
            {isOpen && (
                <DropDownContainer>
                    {hasNestedOptions ? (
                        options.map((group, index) => (
                            <React.Fragment key={index}>
                                <DropDownBlock
                                    value={selectedValue}
                                    options={group}
                                    onSelect={selectOption}
                                    size={size}
                                />
                                {index < options.length - 1 && <Separator/>}
                            </React.Fragment>
                        ))
                    ) : (
                        <DropDownBlock
                            value={selectedValue}
                            options={options}
                            onSelect={selectOption}
                        />
                    )}
                </DropDownContainer>
            )}
        </DropdownWrapper>
    );
};

export default Select;

const DropdownWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const DropdownTrigger = styled.div`
    background: ${({theme}) => theme.colors.Neutral["0"]};;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid ${({theme}) => theme.colors.Neutral['40']};
    box-sizing: border-box;
    height: ${props => props.size === 'small' ? '32px' : '40px'};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Separator = styled.div`
    border-bottom: 1px solid ${({theme}) => theme.colors.Neutral["30"]};
    display: flex;
    align-items: center;
    margin: 0 8px 4px 8px;
`;
