import React from 'react';
import styled, { css } from 'styled-components';
import { Body1, Body2, Helper } from "styles/Typography";
import { Icon, Label} from "components/forms";

const Input = ({
    label,
    value,
    onChange,
    placeholder = "",
    description = "",
    disabled = false,
    icon = "",
    iconPosition = "left",
    size = "small",
    onClick
}) => {
    return (
        <InputGroup onClick={onClick} size={size}>
            <Label text={label} size={size}/>
            <InputFieldContainer>
                <StyledTextInput
                    icon={icon}
                    iconPosition={iconPosition}
                    size={size}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    disabled={disabled}
                />
                {icon && <IconContainer iconPosition={iconPosition}><Icon type={icon} size={18} /></IconContainer>}
            </InputFieldContainer>
            {description && (<HelperText>{description}</HelperText>)}
        </InputGroup>
    );
};

export const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${({ size }) => size === 'small' ? css`
        ${Body2}
    ` : css`
        ${Body1}
    `}
`;


const InputFieldContainer = styled.div`
    position: relative;
    width: fit-content;
`;

const IconContainer = styled.div`
    position: absolute;
    ${({ iconPosition }) => iconPosition === 'left' ? 'left: 10px;' : 'right: 10px;'}
    top: 55%;
    transform: translateY(-50%);
    pointer-events: none;
`;

export const StyledTextInput = styled.input`
    padding: ${({ icon, iconPosition }) => icon ? (iconPosition === "left" ? "10px 10px 10px 36px" : "10px 36px 10px 10px") : "10px"};
    box-sizing: border-box;
    width: 278px;
    height: ${props => props.size === "small" ? "32px" : "40px"};
    border: 1px solid ${({ theme }) => theme.colors.Neutral["40"]};
    border-radius: 4px;

    &:hover {
        background-color: ${({ theme }) => theme.colors.Neutral["15"]};
    }

    &:active {
        border: 1px solid ${({ theme }) => theme.colors.Neutral["60"]};
    }

    &:focus {
        border-color: ${({ theme }) => theme.colors.Neutral["60"]};
        outline: none;
    }
`;

const HelperText = styled(Helper)`
    margin-top: 2px;
    color: ${({ theme }) => theme.colors.Neutral["70"]};
`;

export default Input;
