import styled from 'styled-components';

export const Heading0 = styled.span`
    //styleName: Heading/Heading 0;
    font-family: "Roboto";
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.02em;
`;

export const Heading1 = styled.span`
    //styleName: Heading/Heading 1;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.015em;
`;

export const Heading2 = styled.span`
    //styleName: Heading/Heading 2;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0125em;
`;

export const Heading3 = styled.span`
    //styleName: Heading/Heading 3;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
`;

export const Subheader1 = styled.span`
    //styleName: Subheader/Subheader 1 (button);
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.0085em;
`;

export const Subheader2 = styled.span`
    //styleName: Subheader/Subheader 2;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.0075em;
`;

export const Body1Bold = styled.span`
    //styleName: Body/Body 1 (bold);
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.01em;
`;

export const Body1Medium = styled.span`
    //styleName: Body/Body 1 (medium);
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
`;

export const Body1 = styled.span`
    //styleName: Body/Body 1;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
`;

export const Body2Bold = styled.span`
    //styleName: Body/Body 2 (bold);
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.0085em;
`;

export const Body2Medium = styled.span`
    //styleName: Body/Body 2 (medium);
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.0085em;
`;

export const Body2 = styled.span`
    //styleName: Body/Body 2;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0085em;
`;

export const HelperMedium = styled.span`
    //styleName: Helper/Helper (medium);
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.0075em;
`;

export const Helper = styled.span`
    //styleName: Helper/Helper;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.0075em;
`;
