import React from "react";
import { Input } from "components/forms/index"

const PersonalView = () => {
    return (
        <>
            <p>Personal view</p>
            <div style={ {
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                marginLeft: "20px"
            }}>
            <Input label="Medium" size="medium" placeholder="Type here" icon="small_search" description="Description" />
            <Input label="Small"  placeholder="Type here"/>
            </div>
        </>
    );
}

export default PersonalView;