import React from 'react';
import DropDownItem from './DropDownItem';
import DropDownLabel from './DropDownLabel';

const DropdownBlock = ({value = "", options = [], onSelect, highlightSelected = false, size = "small"}) => {
    const selectedOption = options.filter(option => option.value === value);
    const otherOptions = options.filter(option => option.value !== value);

    const sortedOptions = highlightSelected ? [...selectedOption, ...otherOptions] : options;

    return (
        <>
            {options.length > 0 && (
                sortedOptions.map((option, index) => (
                    <DropDownItem
                        icon={option?.icon}
                        key={index}
                        onClick={() => onSelect(option.value)}
                        isSelected={option.value === value}
                    >
                        <DropDownLabel option={option} isSelected={option.value === value}/>
                    </DropDownItem>
                ))
            )}
        </>
    );
};

export default DropdownBlock;
