import React from "react"
import Tab from "./Tab"
import styled from "styled-components";

const Tabs = ({tabs}) => {
    return (
        <StyledTabs>
            {tabs.map((tab, index) =>
                <React.Fragment key={index}>
                    <Tab label={tab.label} linkTo={tab.linkTo}/>
                </React.Fragment>
            )}
        </StyledTabs>
    )
}

export default Tabs;

const StyledTabs = styled.div`
    display: flex;
`