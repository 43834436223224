import {Body2} from "styles/Typography";
import styled from "styled-components";
import {Icon} from "components/forms";
import {useState} from "react";

const ItemCreate = () => {
    const [isHover, setIsHover] = useState(false);

    return (
        <ItemContainer
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <PlusContainer isHover={isHover}>
                <Icon type="add-line"/>
            </PlusContainer>
            <TextContainer>
                <Body2>Create new template</Body2>
            </TextContainer>
        </ItemContainer>
    );
};

const ItemContainer = styled.div`
    height: 136px;
    width: 279px;
    padding: 40px 41px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: ${({theme}) => theme.colors.Neutral["15"]};
    &:hover {
        box-shadow: 0 0 0 1px ${({theme}) => theme.colors.Neutral["50"]};
    }
`

const TextContainer = styled.div`
    text-align: center;
    margin-top: 10px;
    color: ${({theme}) => theme.colors.Neutral["80"]};
`;

const PlusContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
  background-color: ${({isHover, theme}) => 
        isHover ? theme.colors.Yellow["30"] : theme.colors.Neutral["20"]};
    margin: 0 auto;
`;

export default ItemCreate;
