import React, {useState} from 'react';
import {Button, Input} from "components/forms";
import {postRequest} from "utils/api"

const Insights = () => {
    const [value, setValue] = useState('');

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    const handleClick = async () => {
        const t = {
            "email": value
        }
        const response = await postRequest('/team/', t);
        console.log(response)
    };

    return (
        <>
            <Input
                label="Email"
                placeholder="Enter email..."
                onChange={handleChange}
            />
            <Button onClick={handleClick} text="invite"/>
        </>
    );
};

export default Insights;
