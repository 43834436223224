import React from 'react';
import styled, {css} from 'styled-components';
import {Icon} from 'components/forms/index';
import {Subheader1} from "styles/Typography"

const Button = ({
                    onClick,
                    text,
                    icon = "",
                    iconPosition = "left",
                    disabled = false,
                    type = 'primary',
                    size = 'medium',
                }) => {
    return (
        <StyledButton
            onClick={onClick}
            disabled={disabled}
            buttonType={type}
            buttonSize={size}
            hasText={!!text}
        >
            {icon && iconPosition === 'left' && <Icon type={icon} size={getIconSize(size)}/>}
            {text && <Subheader1>{text}</Subheader1>}
            {icon && iconPosition === 'right' && <Icon type={icon} size={getIconSize(size)}/>}
        </StyledButton>
    );
};

const buttonStyles = {
    primary: {
        default: css`
            background-color: ${({ theme }) => theme.colors.Yellow["50"]};
            color: ${({ theme }) => theme.colors.Neutral["80"]};
        `,
        hover: css`
            background-color: ${({ theme }) => theme.colors.Yellow["55"]};
            color: ${({ theme }) => theme.colors.Neutral["80"]};
        `,
        disabled: css`
            background-color: ${({ theme }) => theme.colors.Neutral["20"]};
            color: ${({ theme }) => theme.colors.Neutral["50"]};
        `,
    },
    secondary: {
        default: css`
            background-color:  ${({ theme }) => theme.colors.Neutral["25"]};
            color: ${({ theme }) => theme.colors.Neutral["80"]};
        `,
        hover: css`
            background-color: ${({ theme }) => theme.colors.Neutral["30"]};
            color: ${({ theme }) => theme.colors.Neutral["80"]};
        `,
        disabled: css`
            background-color: ${({theme}) => theme.colors.Neutral["20"]};
            color: ${({theme}) => theme.colors.Neutral["50"]};
        `,
    },
    tertiary: {
        default: css`
            background-color: transparent;
            color:  ${({ theme }) => theme.colors.Neutral["70"]};
        `,
        hover: css`
            background-color: ${({ theme }) => theme.colors.Neutral["25"]};
            color: ${({ theme }) => theme.colors.Neutral["70"]};
        `,
        disabled: css`
            background-color: transparent;
            color: ${({ theme }) => theme.colors.Neutral["50"]};
        `,
    },
    black: {
        default: css`
            background-color: ${({ theme }) => theme.colors.Neutral["80"]};
            color: ${({ theme }) => theme.colors.Neutral["15"]};;
        `,
        hover: css`
            background-color: ${({ theme }) => theme.colors.Neutral["70"]};
            color: ${({ theme }) => theme.colors.Neutral["15"]};
        `,
        disabled: css`
            background-color: ${({ theme }) => theme.colors.Neutral["20"]};
            color:  ${({ theme }) => theme.colors.Neutral["50"]};
        `,
    },
};

const getSizeStyles = (size, hasText) => {
    const sizeStyles = {
        large: {
            height: '40px',
            padding: hasText ? '11px 16px' : '11px',
            borderRadius: '8px',
        },
        medium: {
            height: '32px',
            padding: hasText ? '7px 12px' : '7px',
            borderRadius: '6px',
        },
        small: {
            height: '24px',
            padding: hasText ? '4px 8px' : '4px',
            borderRadius: '4px',
        },
    };
    return sizeStyles[size];
};

const getIconSize = (buttonSize) => {
    return buttonSize === 'small' ? 16 : 18;
};

const StyledButton = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    gap: 6px;
    ${({buttonSize, hasText}) => getSizeStyles(buttonSize, hasText)}
    
    ${({buttonType}) => buttonStyles[buttonType].default}
    &:hover {
        ${({buttonType}) => buttonStyles[buttonType].hover}
    }
    &:disabled {
        cursor: not-allowed;
        ${({buttonType}) => buttonStyles[buttonType].disabled}
    }
`;

export default Button;
