import React from 'react';
import styled from "styled-components";
import {Body2} from "styles/Typography";
import {Icon} from "../index";


const DropDownLabel = ({option}) => (
    <StyledDropDownLabel>
        {option?.icon && <LabelIcon><Icon size={18} type={option.icon}/></LabelIcon>}
        <Body2>{option.label}</Body2>
    </StyledDropDownLabel>
);

export default DropDownLabel;

const StyledDropDownLabel = styled.div`
    display: flex;
    justify-content: space-between;
`

const LabelIcon = styled.div`
    margin-right: 6px;
`

