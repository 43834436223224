import React from 'react';
import styled from 'styled-components';
import {colors} from "styles/colors";
import {Body1, Helper} from "styles/Typography";
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, ReferenceLine} from 'recharts';

const CustomCursor = ({x, y, width, height}) => {
    const midX = x + width / 2;
    return (
        <g>
            <line
                x1={midX}
                y1={y}
                x2={midX}
                y2={y + height}
                stroke={colors.Neutral[40]}
                strokeWidth={1}
            />
        </g>
    );
};

const CustomTooltip = ({payload, label, active}) => {
    if (active && payload && payload.length) {
        return (
            <TooltipContainer>
                <Body1>{label}</Body1>
                {payload.map((info, index) => (
                    <TooltipInfo key={index}>
                        <LegendEntry>
                            <LegendColorBox color={info.fill}/>
                            <Helper>{info.dataKey}</Helper>
                        </LegendEntry>
                        <Helper>{info.value}</Helper>
                    </TooltipInfo>
                ))}
            </TooltipContainer>
        );
    }
    return null;
};

const StackedBarChart = ({title, data, xAxisKey, bars, activeXAxisLabel = ""}) => {

    const CustomXAxisLabel = ({x, y, payload}) => {
        const isSpecialLabel = payload.value === activeXAxisLabel;
        return (
            <g>
                <text
                    x={x}
                    y={y}
                    dy={15}
                    textAnchor="middle"
                    fill={isSpecialLabel ? colors.Neutral[80] : colors.Neutral[50]}
                    fontSize={12}
                    fontFamily="Roboto"
                >
                    {payload.value}
                </text>
            </g>
        );
    };

    const getReferenceLineProps = (data, xAxisKey, activeXAxisLabel) => {
        if (Array.isArray(data)) {
            const index = data.findIndex(item => item[xAxisKey] === activeXAxisLabel);
            if (index !== -1) {
                return {
                    x: activeXAxisLabel,
                    stroke: colors.Neutral[40],
                    strokeWidth: 1
                };
            }
        }
        return null;
    };

    const referenceLineProps = getReferenceLineProps(data, xAxisKey, activeXAxisLabel);

    return (
        <ChartContainer>
            <ChartHeader>
                <ChartTitle>{title}</ChartTitle>
                <LegendContainer>
                    {bars.map((bar, index) => (
                        <LegendEntry key={index}>
                            <LegendColorBox color={bar.fill}/>
                            <Helper>{bar.dataKey}</Helper>
                        </LegendEntry>
                    ))}
                </LegendContainer>
            </ChartHeader>
            <ResponsiveContainer width="100%" height={264}>
                <BarChart data={data} margin={{top: 8, right: 28, left: -10, bottom: 24}}>
                    <CartesianGrid
                        strokeDasharray="3 3"
                        stroke={colors.Neutral[40]}
                        height={1}
                        vertical={false}
                    />
                    <XAxis
                        dataKey={xAxisKey}
                        tick={CustomXAxisLabel}
                        axisLine={{stroke: colors.Neutral[40]}}
                        tickLine={{stroke: colors.Neutral[40]}}
                    />
                    <YAxis
                        tick={{fontSize: 12, fontFamily: 'Roboto', fill: colors.Neutral[50]}}
                        interval={0}
                        tickCount={6}
                        tickLine={false}
                        axisLine={false}
                    />
                    {referenceLineProps && <ReferenceLine {...referenceLineProps} />}
                    {bars.map((bar, index) => (
                        <Bar
                            key={index}
                            dataKey={bar.dataKey}
                            stackId="a"
                            fill={bar.fill}
                            maxBarSize={data.length > 28 ? 9: 18}
                        />
                    ))}
                    <Tooltip content={<CustomTooltip/>} cursor={<CustomCursor/>}/>
                    <ReferenceLine y={0} stroke={colors.Neutral[40]} height={1}/>
                </BarChart>
            </ResponsiveContainer>
        </ChartContainer>
    );
};

// Styled components
const TooltipContainer = styled.div`
    box-sizing: border-box;
    background: ${({theme}) => theme.colors.Neutral["0"]};
    border-radius: 8px;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: 8px;
    width: 135px;
`;

const TooltipInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ChartContainer = styled.div`
    width: 100%;
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.colors.Neutral["30"]};
`;

const ChartHeader = styled.div`
    padding: 16px 28px 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ChartTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LegendContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
`;

const LegendEntry = styled.div`
    display: flex;
    align-items: center;
    color: ${({theme}) => theme.colors.Neutral["70"]};
`;

const LegendColorBox = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background-color: ${props => props.color};
    margin-right: 5px;
`;

export default StackedBarChart;
