import React from "react";
import {Sidebar} from "components/navigation";
import {Outlet} from "react-router-dom";
import styled from "styled-components";

const ProtectedLayout = () => {
    return (
        <Container>
            <SidebarContent>
                <Sidebar/>
            </SidebarContent>
            <Content>
                <Outlet/>
            </Content>
        </Container>
    );
};

export default ProtectedLayout;

const Container = styled.div`
    max-height: 100vh;
    display: flex;
`;

const SidebarContent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({theme}) => theme.colors.Neutral["15"]};
    height: 100vh;
    border-right: 1px solid ${({ theme }) => theme.colors.Neutral["30"]};
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;