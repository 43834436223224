import React, {useEffect, useState} from 'react';
import {authProvider} from "utils/auth";
import {auth} from "utils/firebaseConfig";
import {
    RouterProvider,
    createBrowserRouter,
    redirect,
    Navigate,
} from "react-router-dom";

import LoginPage from "pages/login";
import {onAuthStateChanged} from "firebase/auth";

import ProtectedLayout from "components/layout/ProtectedLayout"
import TeamLayout from "components/layout/TeamLayout";

import OverviewPage from "pages/team_view/overview"
import Insights from "pages/team_view/Insights";

import AgendaPage from "pages/agenda";
import FeedbackLoop from "pages/FeedbackLoop";
import PersonalView from "pages/PersonalView";
import Settings from "pages/Settings";
import NotFound from "pages/NotFound";


import {ThemeProvider} from 'styled-components';
import {theme} from 'styles/theme';


const router = [
    {
        path: "/",
        loader: protectedLoader,
        element: <ProtectedLayout />,
        errorElement: <NotFound />,
        children: [
            {
                index: true,
                element: <Navigate to="team_view" replace />,
            }, {
                path: "team_view",
                element: <TeamLayout />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="overview" replace />,
                    }, {
                        path: "overview",
                        element: <OverviewPage />,
                    }, {
                        path: "insights",
                        element: <Insights />,
                    }
                ]
            }, {
                path: "agenda",
                element: <AgendaPage/>,
            }, {
                path: "feedback_loop",
                element: <FeedbackLoop />,
            }, {
                path: "personal_view",
                element: <PersonalView />,
            }, {
                path: "settings",
                element: <Settings />,
            },
        ]
    },
    {
        path: "/login",
        loader: loginLoader,
        element: <LoginPage />,
    },
];


function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            authProvider.init(user);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }
    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={createBrowserRouter(router)} fallbackElement={<p>Initial Load...</p>}/>
        </ThemeProvider>
    );
}


async function loginLoader() {
    if (authProvider.accessToken) {
        return redirect("/");
    }
    return null;
}

async function protectedLoader() {
    if (!authProvider.accessToken) {
        return redirect("/login");
    }
    return null;
}

export default App;
