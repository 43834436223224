import React from "react";
import {Outlet} from "react-router-dom";
import {Tabs} from "components/navigation";
import styled from "styled-components";

const TeamLayout = () => {
    const tabs = [
        {label: "Overview", linkTo: "/team_view/overview"},
        {label: "Insights", linkTo: "/team_view/insights"},
    ]
    return (
        <>
            <ContentHeader>
                <Tabs tabs={tabs}/>
            </ContentHeader>
            <ContentMain>
                <Outlet/>
            </ContentMain>
        </>
    );
};

export default TeamLayout;

const ContentHeader = styled.div`
    padding: 16px 32px 0 32px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.Neutral["30"]};
`
const ContentMain = styled.div`
    flex: 1;
    padding: 32px;
`
