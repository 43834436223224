import React, {useEffect} from "react";
import styled from "styled-components"


const InfosContainer = ({selMember, startDate, endDate, typeView}) => {
    useEffect(() => {
        if (selMember && startDate && endDate && typeView) {
            console.log(1)
        }
    }, [selMember, startDate, endDate, typeView])

    return (
        <>
            <GridContainer>
                <StyledContainer/>
                <StyledContainer/>
                <StyledContainer/>
                <StyledContainer/>
                <StyledContainer/>
            </GridContainer>
        </>
    )
}

export default InfosContainer;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr); 
    gap: 20px;
`;


const StyledContainer = styled.div`
    width: 100%;
    height: 103px;
    border-radius: 8px;
    border: 1px solid;
    box-sizing: border-box;
    border-color: ${({theme}) => theme.colors.Neutral["30"]};
    background-color: ${({theme}) => theme.colors.Neutral["15"]};
`;