import styled from "styled-components";
import {useEffect, useState} from "react";
import {StackedBarChart, Icon} from "components/forms";
import {getRequest} from "utils/api";
import {formatDate} from "utils/date";
import {Body1} from "styles/Typography";
import {colors} from "styles/colors";

import {isWithinInterval, parse, endOfDay} from 'date-fns';

const PlotsContainer = ({selMember, startDate, endDate, typeView}) => {
    const [plotData, setPlotData] = useState("");
    const [activeLabel, setActiveLabel] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            if (startDate && endDate && endDate) {
                const params = {
                    start_date: startDate,
                    end_date: endDate,
                    type: typeView,
                }
                try {
                    const result = await getRequest("/team/chart/", params);
                    setPlotData(result.data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchData();

    }, [selMember, startDate, endDate, typeView])


    useEffect(() => {
        const findActiveLabel = () => {
            let activeEntry = "";
            const today = new Date();
            if (typeView === "day") {
                activeEntry = formatDate(today, "MMM d");
            } else if (typeView === "week") {
                if (Array.isArray(plotData)) {
                    const active = plotData.find(item => {
                        const [start, end] = item['Date'].split(' - ');
                        if (start && end) {
                            const weekStart = parse(start, 'MMM dd', new Date());
                            const weekEndDate = parse(end, 'MMM dd', new Date());
                            const weekEnd = endOfDay(weekEndDate);
                            return isWithinInterval(today, {start: weekStart, end: weekEnd});
                        }
                        return false;
                    })
                    activeEntry = active ? active['Date'] : ""
                }
            }
            setActiveLabel(activeEntry);
        };
        findActiveLabel();
    }, [plotData, typeView, setActiveLabel]);

    const barConfig = [
        {dataKey: 'Recurring', fill: colors.Chart[1]},
        {dataKey: 'One-time', fill: colors.Chart[2]},
    ];

    const plotName = (
        <>
            <Body1>Meetings Cost</Body1>
            <LabelIcon><Icon type="dollar-icon" size={20}/></LabelIcon>
        </>
    )

    return (
        <Container>
            <StackedBarChart title={plotName} data={plotData} xAxisKey="Date" bars={barConfig}
                             activeXAxisLabel={activeLabel}/>
            <StackedBarChart title={plotName} data={plotData} xAxisKey="Date" bars={barConfig}
                             activeXAxisLabel={activeLabel}/>
            <StackedBarChart title={plotName} data={plotData} xAxisKey="Date" bars={barConfig}
                             activeXAxisLabel={activeLabel}/>
            <StackedBarChart title={plotName} data={plotData} xAxisKey="Date" bars={barConfig}
                             activeXAxisLabel={activeLabel}/>
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(564px, 1fr));
    gap: 24px;
`;

const LabelIcon = styled.div`
    margin-left: 8px;
`
export default PlotsContainer;


