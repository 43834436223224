import React, {useState} from 'react';
import {Icon} from "components/forms";
import styled from 'styled-components';



const commentsData = [
    {
        bigText: '$37B',
        text: 'approximate cost that companies spend on meetings annually.',
        link: 'https://hbr.org/2018/02/how-to-fix-the-most-soul-crushing-meetings',
        name: 'Harvard Business Review',
    },
    {
        bigText: '252%',
        text: 'increase in meeting time for the typical employee since February 2020',
        link: 'https://www.microsoft.com/en-us/worklab/work-trend-index/great-expectations-making-hybrid-work-work',
        name: 'Microsoft report',
    },
    {
        bigText: '$25000',
        text: 'costs of the meeting time per employee, annually.',
        link: 'https://www.nytimes.com/2023/04/07/business/office-meetings-time.html',
        name: 'NY Times',
    },
];

const LoginPage = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleRightClick = () => {
        setCurrentIndex((prevState) => (prevState + 1) % commentsData.length);
    };

    const handleLeftClick = () => {
        setCurrentIndex(
            (prevState) => (prevState - 1 + commentsData.length) % commentsData.length
        );
    };


    return (
        <StyledDiv>
            <LeftB onClick={handleLeftClick}>
                <Icon type="arrow-left-line"/>
            </LeftB>
            <CommentsContainer>
                <BigText>{commentsData[currentIndex].bigText}</BigText>
                <Text>{commentsData[currentIndex].text}</Text>
                <Link
                    href={commentsData[currentIndex].link}
                    target='_blank'
                >
                    {commentsData[currentIndex].name}
                    <Icon type="arrow-right-line"/>
                </Link>
            </CommentsContainer>
            <RightB onClick={handleRightClick}>
                <Icon type="arrow-right-line"/>
            </RightB>
        </StyledDiv>
    );
};

export default LoginPage;


const Link = styled.a`
    text-decoration: none;
    color: white;
    margin-right: 8px;
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 40px;

    &:hover {
        color: #ffcf40;
    }

    svg {
        margin-left: 8px;
    }
`;


const LeftB = styled.div`
    width: 40px;
    height: 40px;
    margin-left:38px;
    display: flex;
    justify-content: center;
    max-width: 40px;
    max-height: 40px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.2);

    &:hover {
        color: rgba(255, 255, 255, 0.8);
        border-color: rgba(255, 255, 255, 0.8);
    }
`;

const RightB = styled.div`
    width: 40px;
    height: 40px;
    margin-right: 38px;
    display: flex;
    justify-content: center;
    max-width: 40px;
    max-height: 40px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.2);

    &:hover {
        color: rgba(255, 255, 255, 0.8);
        border-color: rgba(255, 255, 255, 0.8);
    }
`

const CommentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 485px;
`;

const Text = styled.div`
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    font-family: 'Inter';
    color: #ffffff;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 70px;
    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

const BigText = styled.div`
    font-family: 'Inter';
    font-size: 8vh;
    color: #ffffff;
    font-weight: 500;
    line-height: 82px;
`;