import React from 'react';
import { ReactComponent as AccountBoxFill } from 'assets/images/icons/account-box-fill.svg';
import { ReactComponent as AccountBoxLine } from 'assets/images/icons/account-box-line.svg';
import { ReactComponent as AccountCircleFill } from 'assets/images/icons/account-circle-fill.svg';
import { ReactComponent as AccountCircleLine } from 'assets/images/icons/account-circle-line.svg';
import { ReactComponent as AddLargeLine } from 'assets/images/icons/add-large-line.svg';
import { ReactComponent as DollarSign } from 'assets/images/icons/dollar.svg';
import { ReactComponent as AddLine } from 'assets/images/icons/add-line.svg';
import { ReactComponent as ArrowDownFill } from 'assets/images/icons/arrow-down-s-fill.svg';
import { ReactComponent as ArrowDownLine } from 'assets/images/icons/arrow-down-line.svg';
import { ReactComponent as ArrowDropLeftFill } from 'assets/images/icons/arrow-drop-left-fill.svg';
import { ReactComponent as ArrowDropRightFill } from 'assets/images/icons/arrow-drop-right-fill.svg';
import { ReactComponent as ArrowDropUpFill } from 'assets/images/icons/arrow-drop-up-fill.svg';
import { ReactComponent as ArrowLeftLine } from 'assets/images/icons/arrow-left-line.svg';
import { ReactComponent as ArrowLeftSFill } from 'assets/images/icons/arrow-left-s-fill.svg';
import { ReactComponent as ArrowLeftSLine } from 'assets/images/icons/arrow-left-s-line.svg';
import { ReactComponent as ArrowRightLine } from 'assets/images/icons/arrow-right-line.svg';
import { ReactComponent as ArrowRightSFill } from 'assets/images/icons/arrow-right-s-fill.svg';
import { ReactComponent as ArrowRightSLine } from 'assets/images/icons/arrow-right-s-line.svg';
import { ReactComponent as ArrowSortingHighFill } from 'assets/images/icons/arrow-sorting-high-fill.svg';
import { ReactComponent as ArrowSortingLowFill } from 'assets/images/icons/arrow-sorting-low-fill.svg';
import { ReactComponent as ArrowUpLine } from 'assets/images/icons/arrow-up-line.svg';
import { ReactComponent as ArrowUpSFill } from 'assets/images/icons/arrow-up-s-fill.svg';
import { ReactComponent as ArrowUpSLine } from 'assets/images/icons/arrow-up-s-line.svg';
import { ReactComponent as DefaultLogo } from 'assets/images/default_logo.svg';
import { ReactComponent as BarChartBoxFill } from 'assets/images/icons/bar-chart-box-fill.svg';
import { ReactComponent as BarChartBoxLine } from 'assets/images/icons/bar-chart-box-line.svg';
import { ReactComponent as CheckboxCircleLine } from 'assets/images/icons/checkbox-circle-line.svg';
import { ReactComponent as CheckLine } from 'assets/images/icons/check-line.svg';
import { ReactComponent as CloseLargeLine } from 'assets/images/icons/close-large-line.svg';
import { ReactComponent as CloseLine } from 'assets/images/icons/close-line.svg';
import { ReactComponent as DeleteBinLine } from 'assets/images/icons/delete-bin-line.svg';
import { ReactComponent as DownloadLine } from 'assets/images/icons/download-line.svg';
import { ReactComponent as EmotionHappyFill } from 'assets/images/icons/emotion-happy-fill.svg';
import { ReactComponent as EmotionHappyLine } from 'assets/images/icons/emotion-happy-line.svg';
import { ReactComponent as ErrorWarningLine } from 'assets/images/icons/error-warning-line.svg';
import { ReactComponent as ExpandLeftLine } from 'assets/images/icons/expand-left-line.svg';
import { ReactComponent as ExpandRightLine } from 'assets/images/icons/expand-right-line.svg';
import { ReactComponent as EyeLine } from 'assets/images/icons/eye-line.svg';
import { ReactComponent as EyeOffLine } from 'assets/images/icons/eye-off-line.svg';
import { ReactComponent as FileList2Fill } from 'assets/images/icons/file-list-2-fill.svg';
import { ReactComponent as FileList2Line } from 'assets/images/icons/file-list-2-line.svg';
import { ReactComponent as Group3Fill } from 'assets/images/icons/group-3-fill.svg';
import { ReactComponent as Group3Line } from 'assets/images/icons/group-3-line.svg';
import { ReactComponent as InformationLine } from 'assets/images/icons/information-line.svg';
import { ReactComponent as LightbulbFlashFill } from 'assets/images/icons/lightbulb-flash-fill.svg';
import { ReactComponent as LightbulbFlashLine } from 'assets/images/icons/lightbulb-flash-line.svg';
import { ReactComponent as LoginBoxLine } from 'assets/images/icons/login-box-line.svg';
import { ReactComponent as LogoutBoxLine } from 'assets/images/icons/logout-box-line.svg';
import { ReactComponent as MenuLine } from 'assets/images/icons/menu-line.svg';
import { ReactComponent as More2Fill } from 'assets/images/icons/more-2-fill.svg';
import { ReactComponent as QuestionLine } from 'assets/images/icons/question-line.svg';
import { ReactComponent as SearchLine } from 'assets/images/icons/search-line.svg';
import { ReactComponent as SettingsFill } from 'assets/images/icons/settings-fill.svg';
import { ReactComponent as SettingsLine } from 'assets/images/icons/settings-line.svg';
import { ReactComponent as Sparkling2Fill } from 'assets/images/icons/sparkling-2-fill.svg';
import { ReactComponent as Sparkling2Line } from 'assets/images/icons/sparkling-2-line.svg';
import { ReactComponent as UploadLine } from 'assets/images/icons/upload-line.svg';
import { ReactComponent as SmallSearch } from 'assets/images/icons/small_search.svg';
import { ReactComponent as ChromeColor } from 'assets/images/icons/chrome-color.svg';
import { ReactComponent as ChromeLine } from 'assets/images/icons/chrome-line.svg';
import { ReactComponent as Facebook } from 'assets/images/icons/facebook.svg';
import { ReactComponent as GoogleColor } from 'assets/images/icons/google color.svg';
import { ReactComponent as GoogleLine } from 'assets/images/icons/google line.svg';
import { ReactComponent as Instagram } from 'assets/images/icons/instagram.svg';
import { ReactComponent as Linkedin } from 'assets/images/icons/linkedin.svg';
import { ReactComponent as X } from 'assets/images/icons/x.svg';
import { ReactComponent as ArrowDownSLine } from 'assets/images/icons/arrow-down-s-line.svg';
import { ReactComponent as CalendarEventLine } from 'assets/images/icons/calendar-event-line.svg';


const dictWithIcons = {
    "chrome-color": ChromeColor,
    "chrome-line": ChromeLine,
    "facebook": Facebook,
    "google-color": GoogleColor,
    "google-line": GoogleLine,
    "instagram": Instagram,
    "linkedin": Linkedin,
    "X": X,
    "account-box-fill": AccountBoxFill,
    "account-box-line": AccountBoxLine,
    "account-circle-fill": AccountCircleFill,
    "account-circle-line": AccountCircleLine,
    "add-large-line": AddLargeLine,
    "add-line": AddLine,
    "arrow-down-s-line": ArrowDownSLine,
    "arrow-down-fill": ArrowDownFill,
    "arrow-down-line": ArrowDownLine,
    "arrow-drop-left-fill": ArrowDropLeftFill,
    "arrow-drop-right-fill": ArrowDropRightFill,
    "arrow-drop-up-fill": ArrowDropUpFill,
    "arrow-left-line": ArrowLeftLine,
    "arrow-left-s-fill": ArrowLeftSFill,
    "arrow-left-s-line": ArrowLeftSLine,
    "arrow-right-line": ArrowRightLine,
    "arrow-right-s-fill": ArrowRightSFill,
    "arrow-right-s-line": ArrowRightSLine,
    "arrow-sorting-high-fill": ArrowSortingHighFill,
    "arrow-sorting-low-fill": ArrowSortingLowFill,
    "arrow-up-line": ArrowUpLine,
    "arrow-up-s-fill": ArrowUpSFill,
    "arrow-up-s-line": ArrowUpSLine,
    "bar-chart-box-fill": BarChartBoxFill,
    "bar-chart-box-line": BarChartBoxLine,
    "checkbox-circle-line": CheckboxCircleLine,
    "close-large-line": CloseLargeLine,
    "close-line": CloseLine,
    "delete-bin-line": DeleteBinLine,
    "download-line": DownloadLine,
    "emotion-happy-fill": EmotionHappyFill,
    "emotion-happy-line": EmotionHappyLine,
    "error-warning-line": ErrorWarningLine,
    "expand-left-line": ExpandLeftLine,
    "expand-right-line": ExpandRightLine,
    "eye-line": EyeLine,
    "eye-off-line": EyeOffLine,
    "file-list-2-fill": FileList2Fill,
    "file-list-2-line": FileList2Line,
    "group-3-fill": Group3Fill,
    "group-3-line": Group3Line,
    "information-line": InformationLine,
    "lightbulb-flash-fill": LightbulbFlashFill,
    "lightbulb-flash-line": LightbulbFlashLine,
    "login-box-line": LoginBoxLine,
    "logout-box-line": LogoutBoxLine,
    "menu-line": MenuLine,
    "more-2-fill": More2Fill,
    "question-line": QuestionLine,
    "search-line": SearchLine,
    "settings-fill": SettingsFill,
    "settings-line": SettingsLine,
    "sparkling-2-fill": Sparkling2Fill,
    "sparkling-2-line": Sparkling2Line,
    "upload-line": UploadLine,
    "logo": DefaultLogo,
    "check-line": CheckLine,
    "small_search": SmallSearch,
    "dollar-icon": DollarSign,
    "calendar-event-line":CalendarEventLine
};


const Icon = ({type, size = 18, fill = 'currentColor'}) => {
    const IconComponent = dictWithIcons[type];
    if (!IconComponent) return null;
    return (
        <IconComponent width={size} height={size} fill={fill}/>
    );
};

export default Icon;
