import React from 'react';
import styled from 'styled-components';
import SwitchLabel from "./SwitchLabel";


const Checkbox = ({ label, value, onChange }) => (
  <CheckboxContainer>
    <StyledCheckbox checked={value} onChange={onChange} />
    <SwitchLabel text={label}/>
  </CheckboxContainer>
);

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
    width: 15px;
    height: 15px;
    cursor: pointer;
    border: 3px solid ${({theme}) => theme.colors.Neutral["40"]};;
    accent-color: ${({theme}) => theme.colors.Neutral["70"]};;
    
    &:hover {
        border: 3px solid ${({theme}) => theme.colors.Neutral["50"]};;
        accent-color: ${({theme}) => theme.colors.Neutral["80"]};;
    }
`;
export default Checkbox;
