import React, {useState} from "react";
import styled from "styled-components";
import SidebarItem from "./SidebarItem";
import {Icon, Button} from "components/forms";

const Sidebar = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [isHoveredSideBar, setIsHoveredSideBar] = useState(false);

    const toggleSideBar = () => {
        setIsExpanded(!isExpanded);
        setIsHoveredSideBar(false);
    }

    const menuItems = [
        [
            {icon: "group-3-line", label: "Team view", linkTo: "/team_view"},
            {icon: "account-circle-line", label: "Personal view", linkTo: "/personal_view"},
        ], [
            {icon: "file-list-2-line", label: "Agenda", linkTo: "/agenda"},
            {icon: "emotion-happy-line", label: "Feedback loop", linkTo: "/feedback_loop"},
        ]
    ];

    return (
        <SidebarContainer
            onMouseEnter={() => setIsHoveredSideBar(true)}
            onMouseLeave={() => setIsHoveredSideBar(false)}
        >
            <SidebarHeader>
                {(isExpanded || !isHoveredSideBar) && <Icon size={32} type="logo"/>}
                {isHoveredSideBar &&
                    <Button icon={isExpanded ? "expand-left-line" : "expand-right-line"} onClick={toggleSideBar} type="tertiary"/>
                }
            </SidebarHeader>
            <SidebarMenu>
                {menuItems.map((block, index) => (
                    <React.Fragment key={index}>
                        <SidebarBlock>
                            {block.map((item) => (
                                <SidebarItem
                                    key={item.linkTo}
                                    icon={item.icon}
                                    linkTo={item.linkTo}
                                    label={item.label}
                                    expanded={isExpanded}
                                />
                            ))}
                        </SidebarBlock>
                        {index === 0 && <Separator/>}
                    </React.Fragment>
                ))}
            </SidebarMenu>
            <SidebarFooter>
                <SidebarItem
                    icon="settings-line"
                    label="Settings"
                    linkTo="/settings"
                    expanded={isExpanded}
                />
            </SidebarFooter>
        </SidebarContainer>
    );
};

export default Sidebar;

const SidebarContainer = styled.div`
`;

const SidebarHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 16px 0 16px;
    padding-bottom: 20px;
`;

const SidebarMenu = styled.div`
    flex: 1;
    margin: 0 16px;
`;

const SidebarFooter = styled.div`
    margin: 0 16px 16px 16px;
    position:absolute;
    bottom:0;
`;

const SidebarBlock = styled.div`
`;

const Separator = styled.div`
    border-bottom: 1px solid #E3E3E5;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 16px 0;
`;
